import React from 'react'
import {useTranslation} from 'react-i18next'

import {MainLayoutWithoutStretch, MainContent} from '../../Layout/Layout'
import SettingsListItem from './SettingsListItem'
import {ListContainer} from '../../Atoms/Lists'
import {
  IconUsers,
  IconProfile,
  IconLocation,
  IconTaskList,
  IconAlarmMessage,
  IconFridge
} from '../../../Assets/Icons/LargeIcons'
import {Baseline} from '../../Layout/Grid'
import {useNavigation} from 'react-navi'
import {HeaderTitle, HeaderRow} from '../../Molecules/ViewComponents/ViewHeader'
import {useAppState} from '../../../state'
import {Helmet} from 'react-navi-helmet-async'

const SettingsView = () => {
  const {me} = useAppState().state
  const navi = useNavigation()
  const {t} = useTranslation('settings')
  const orgLevels = Object.keys(me!.accessRights.write) as Array<'brands' | 'locations' | 'chains' | 'groups' | 'sites'>
  const writableIds = orgLevels.flatMap(level => me!.accessRights.write[level])
  const isBasicUser = writableIds.length === 0

  return (
    <MainLayoutWithoutStretch>
      <Helmet title={t('common:routes.settings', 'Settings')} />
      <MainContent variant="white">
        <Baseline>
          <HeaderRow>
            <HeaderTitle>{t('settings:labels.settingsTitle', 'Settings')}</HeaderTitle>
          </HeaderRow>
          <ListContainer>
            {!isBasicUser && (
              <>
                <SettingsListItem
                  key="1"
                  onClick={() => navi.navigate('/settings/locations')}
                  icon={<IconLocation />}
                  title={t('settings:labels.locationSettings', 'Location settings')}
                  description={t(
                    'settings:descriptions.locationSettings',
                    'Add new, edit, and delete brands, groups, locations and sites. Edit organisation hierarchy.'
                  )}
                />

                <SettingsListItem
                  key="2"
                  onClick={() => navi.navigate('/edit/tasks')}
                  icon={<IconTaskList />}
                  title={t('settings:labels.taskSettings', 'Task settings')}
                  description={t('settings:descriptions.taskSettings', 'Add new, edit, and delete tasks.')}
                />
                <SettingsListItem
                  key="3"
                  onClick={() => navi.navigate('/settings/alarms')}
                  title={t('settings:labels.alarmSettings', 'Alarm message settings')}
                  description={t(
                    'settings:descriptions.alarmSettings',
                    'Add new, edit, and delete alarm message rules. Remove alarms that are not valid.'
                  )}
                  icon={<IconAlarmMessage />}
                />
                <SettingsListItem
                  key="4"
                  onClick={() => navi.navigate('/appliance')}
                  icon={<IconFridge />}
                  title={t('settings:labels.applianceSettings', 'Appliance settings')}
                  description={t(
                    'settings:descriptions.applianceSettings',
                    'Add new, edit, and delete kitchen appliances. Set alarm limits.'
                  )}
                />
                <SettingsListItem
                  key="5"
                  onClick={() => navi.navigate('/settings/users')}
                  icon={<IconUsers />}
                  title={t('settings:labels.userSettings', 'User account settings')}
                  description={t(
                    'settings:descriptions.userSettings',
                    'Add new, edit, and delete user accounts.'
                  )}
                />
              </>
            )}
            <SettingsListItem
              key="6"
              onClick={() => navi.navigate('/settings/useraccount')}
              icon={<IconProfile />}
              title={t('settings:labels.myProfileSettings', 'My profile settings')}
              description={t(
                'settings:descriptions.myProfileSettings',
                'Edit your contact details and change your password.'
              )}
            />
            {/* <SettingsListItem
              key="7"
              onClick={() => navi.navigate('/settings/useraccount')}
              icon={<IconSettings />}
              title={t('General settings')}
              description={t('Change password and other general service settings')}
            /> */}
          </ListContainer>
        </Baseline>
      </MainContent>
    </MainLayoutWithoutStretch>
  )
}

export default SettingsView
