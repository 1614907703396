import React from 'react'
import styled from 'styled-components'
import {colors} from '../../../colors'
import ReactDatePicker from 'react-datepicker'
import {addDays, getMonth, getYear, subDays} from 'date-fns'
import {range} from 'lodash'
import {useTranslation} from 'react-i18next'

const DEFAULT_FORMAT = 'dd.MM.y'
const DEFAULT_LOCALE = 'en-GB'

const years = range(1990, getYear(new Date()) + 50, 1)
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

interface CustomHeaderProps {
  increaseMonth: () => void
  prevMonthButtonDisabled: boolean
  nextMonthButtonDisabled: boolean
  date: Date
  changeMonth: (month: any) => void
  changeYear: (year: any) => void
  decreaseMonth: () => void
  monthTraslations: any
}

const CustomHeader = ({
  decreaseMonth,
  prevMonthButtonDisabled,
  date,
  changeYear,
  changeMonth,
  increaseMonth,
  nextMonthButtonDisabled,
  monthTraslations
}: CustomHeaderProps) => {
  return (
    <div
      style={{
        margin: 10,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
        {'<'}
      </button>
      <select value={getYear(date)} onChange={({target: {value}}) => changeYear(value)}>
        {years.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select value={months[getMonth(date)]} onChange={({target: {value}}) => changeMonth(months.indexOf(value))}>
        {months.map(option => (
          <option key={option} value={option}>
            {monthTraslations[option]}
          </option>
        ))}
      </select>

      <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
        {'>'}
      </button>
    </div>
  )
}

export type DatePickerProps = {
  format?: string
  id?: string
  locale?: string
  maxDays?: number
  startDate: Date
  endDate: Date
  onChange: (name: string, value: Date) => void
}

const DatePickerWrapper = styled.div`
  width: 100%;
  .react-datepicker-wrapper {
    width: 50%;
    padding: 0 0.5rem 0 0;
  }
  input {
    width: 100%;
    flex-wrap: wrap;
    border: 1px solid ${colors.system.grey_5};
    border-radius: 0.25rem;
    background-color: white;
    min-height: 38px;
    height: 100%;
    justify-content: space-between;
    padding-left: 0.5rem;
    flex-grow: 0;
    text-align: left;
  }

  .react-datepicker__close-icon::after {
    background-color: #2e3b47 !important;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #2e3b47 !important;
  }
  .react-datepicker__day--selected {
    background-color: #2e3b47 !important;
  }

  svg {
    stroke: ${colors.system.grey_5};
  }
  svg:hover {
    fill: ${colors.system.blue};
    stroke: ${colors.system.blue};
  }
`

export const DatePicker: React.FC<DatePickerProps> = ({
  id,
  format = DEFAULT_FORMAT,
  locale = DEFAULT_LOCALE,
  ...rest
}) => {
  const maxDateEnd = rest.maxDays ? addDays(rest.startDate, rest.maxDays) : null
  const minDateStart = rest.maxDays ? subDays(rest.endDate, rest.maxDays) : null
  const {t} = useTranslation('common')

  const monthTraslations: any = {
    January: t('common:calendar.month.0', 'January'),
    February: t('common:calendar.month.1', 'February'),
    March: t('common:calendar.month.2', 'March'),
    April: t('common:calendar.month.3', 'April'),
    May: t('common:calendar.month.4', 'May'),
    June: t('common:calendar.month.5', 'June'),
    July: t('common:calendar.month.6', 'July'),
    August: t('common:calendar.month.7', 'August'),
    September: t('common:calendar.month.8', 'September'),
    October: t('common:calendar.month.9', 'October'),
    November: t('common:calendar.month.10', 'November'),
    December: t('common:calendar.month.11', 'December')
  }

  return (
    <DatePickerWrapper id={id}>
      <div style={{display: 'flex'}}>
        <ReactDatePicker
          renderCustomHeader={({
            decreaseMonth,
            prevMonthButtonDisabled,
            date,
            changeYear,
            changeMonth,
            increaseMonth,
            nextMonthButtonDisabled
          }) =>
            CustomHeader({
              decreaseMonth,
              prevMonthButtonDisabled,
              date,
              changeYear,
              changeMonth,
              increaseMonth,
              nextMonthButtonDisabled,
              monthTraslations
            })
          }
          todayButton={t('common:dateNames.today', 'today')}
          selected={rest.startDate}
          selectsStart
          onChange={(date: Date) => rest.onChange('startDate', date)}
          dateFormat={format}
          isClearable={true}
          placeholderText={t('common:labels.calendar.start', 'Start Date')}
          locale={'fi'}
          strictParsing
          minDate={minDateStart}
        />
        <ReactDatePicker
          renderCustomHeader={({
            decreaseMonth,
            prevMonthButtonDisabled,
            date,
            changeYear,
            changeMonth,
            increaseMonth,
            nextMonthButtonDisabled
          }) =>
            CustomHeader({
              decreaseMonth,
              prevMonthButtonDisabled,
              date,
              changeYear,
              changeMonth,
              increaseMonth,
              nextMonthButtonDisabled,
              monthTraslations
            })
          }
          todayButton={t('common:dateNames.today', 'today')}
          selected={rest.endDate}
          selectsEnd
          locale={locale}
          onChange={(date: Date) => rest.onChange('endDate', date)}
          dateFormat={format}
          isClearable={true}
          placeholderText={t('common:labels.calendar.end', 'End Date')}
          maxDate={maxDateEnd}
          strictParsing
        />
      </div>
    </DatePickerWrapper>
  )
}
