import React from 'react'
import styled, {css} from 'styled-components'
import {colors} from '../../../colors'

const getTextStyles = (props: TextPropsWithFontFamily) => css`
  color: ${props.color};
  text-transform: ${props.textTransform};
  white-space: ${props.lineBreak ? 'pre-line' : 'normal'};
  font-style: ${props.italic ? 'italic' : 'normal'};
  font-family: ${props.fontFamilies};
  cursor: ${props.onClick ? 'pointer' : 'inherit'};
  :hover {
    color: ${props.onClick ? colors.system.grey_50 : props.color};
  }
`

const XL = styled.span<TextPropsWithFontFamily>`
  ${getTextStyles}
  font-size: 1.4375rem; /*23px;*/
  line-height: 1.8125rem; /*29px;*/
  letter-spacing: 0.37px;
`

const L = styled.span<TextPropsWithFontFamily>`
  ${getTextStyles}
  font-size: 1.1875rem; /*19px;*/
  line-height: 1.5rem; /*24px;*/
  letter-spacing: -0.49px;
`

const M = styled.span<TextPropsWithFontFamily>`
  ${getTextStyles}
  font-size: 1.06rem; /*17px;*/
  line-height: 1.375rem; /*22px;*/
  letter-spacing: -0.41px;
`

const S = styled.span<TextPropsWithFontFamily>`
  ${getTextStyles}
  font-size: 0.875rem; /*14px;*/
  line-height: 1.1875rem; /*19px;*/
  letter-spacing: -0.15px;
`

const XS = styled.span<TextPropsWithFontFamily>`
  ${getTextStyles}
  font-size: 0.75rem; /*12px;*/
  line-height: 1rem; /*16px;*/
`

const XXS = styled.span<TextPropsWithFontFamily>`
  ${getTextStyles}
  font-size: 0.6875rem; /*11px;*/
  line-height: 0.8125rem; /*13px;*/
  letterspacing: 0.07px;
`

const TextGroup = {XL, L, M, S, XS, XXS}

export type TextSize = 'XXS' | 'XS' | 'S' | 'M' | 'L' | 'XL'

export interface TextProps extends React.HTMLAttributes<HTMLSpanElement> {
  color?: string
  disabled?: boolean
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize'
  size: TextSize
  lineBreak?: boolean

  strong?: boolean
  light?: boolean
  mono?: boolean
  italic?: boolean
  'data-cy'?: string // attribute to the element gives us a targeted selector that's only used for testing
}

interface TextPropsWithFontFamily extends TextProps {
  fontFamilies: string
}

export const Text: React.FC<TextProps> = props => {
  const TextBody = TextGroup[props.size]
  let fontFamilies = 'FavoritStd-Book, sans-serif'
  if (props.strong) fontFamilies = 'FavoritStd-Bold, sans-serif'
  if (props.light) fontFamilies = 'FavoritStd-Light, sans-serif'
  if (props.mono) fontFamilies = 'FavoritMonoStd-Regular, sans-serif'
  return <TextBody fontFamilies={fontFamilies} {...props} />
}
