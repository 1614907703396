import React from 'react'
import ConfirmModal from '../../Molecules/Modals/ConfirmModal'
import {BigLabelInput, Label, LabelInputWrapper, LabelTextArea, Select} from '../../Atoms/Forms'
import {AssetGroup} from '../Assets/AssetWidget'
import {IconText} from '../../Atoms/IconText'
import {IconDelete, IconDocument, IconLink} from '../../../Assets/Icons/TinyIcons'
import {AssetData, AssetType, Document, DocumentFolder, DocumentType} from '../../../state/rest'
import {AssetTabNavigatorProps} from '../Assets/types'
import CenteredModal, {WideModalContent} from '../../Molecules/Modals/Modals'
import AddAsset from '../Assets/AddAsset'
import {P} from '../../Atoms/Typography'
import {Button, ButtonRowGrid, ButtonRowWrap} from '../../../Components/Atoms/Buttons'
import {InstructionsDocument, InstructionsFolder} from 'state/instructions/state'

interface ConfirmModalWindowProps {
  t: any
  setModalOpen: (isModalOpen: boolean) => void
  handleDelete: any
  modalOpen: boolean
}

export const ConfirmModalWindow = ({t, setModalOpen, handleDelete, modalOpen}: ConfirmModalWindowProps) => {
  return (
    <ConfirmModal
      heading={t('documents:confirmation.deleteDocumentTitle', 'Delete document?')}
      content={t('documents:confirmation.deleteDocumentDescription', 'Are you sure you want to delete this document?')}
      actions={[
        {
          label: t('common:actions.cancel', 'Cancel'),
          action: () => setModalOpen(false)
        },
        {
          label: t('common:actions.delete', 'Delete'),
          action: () => {
            setModalOpen(false)
            handleDelete()
          }
        }
      ]}
      isOpen={modalOpen}
    />
  )
}

interface DocumentTypeSelectionProps {
  isEditing: boolean
  document: Partial<Document>
  t: any
  showUploader: boolean
  toggleShowUploader: (showUploader: boolean) => void
  handleSelectType: (type: DocumentType) => void
}

export const DocumentTypeSelection = ({
  isEditing,
  document,
  toggleShowUploader,
  handleSelectType,
  t,
  showUploader
}: DocumentTypeSelectionProps) => {
  return (
    <>
      {isEditing && document.type === DocumentType.Internal && document.asset && <div>{document.asset.name}</div>}

      {!isEditing && !document.type && (
        <>
          <LabelInputWrapper>
            <Label>{t('documents:labels.uploadOrLink', 'Upload or link a document.')}</Label>
          </LabelInputWrapper>
          <AssetGroup>
            <IconText
              icon={<IconDocument />}
              text={t('documents:labels.uploadDocument', 'Upload a document…')}
              onClick={() => toggleShowUploader(!showUploader)}
            />
            <IconText
              icon={<IconLink />}
              text={t('documents:labels.linkDocument', 'Link a document…')}
              onClick={() => handleSelectType(DocumentType.External)}
            />
          </AssetGroup>
        </>
      )}

      {!isEditing && document.type === DocumentType.Internal && document.asset && <div>{document.asset.name}</div>}
    </>
  )
}

interface DocumentAddingModalWindowProps {
  t: any
  showUploader: boolean
  toggleShowUploader: (showUploader: boolean) => void
  handleAddAsset: (asset?: AssetData) => void
  instructions?: boolean
}

export const DocumentAddingModalWindow = ({
  t,
  showUploader,
  toggleShowUploader,
  handleAddAsset,
  instructions
}: DocumentAddingModalWindowProps) => {
  const getAllowedFileTypes = () => {
    const fileTypes = [
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', //PPTX
      'application/vnd.ms-powerpoint', //PPT
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //DOCX
      'application/msword', //DOC
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //XLSX
      'application/vnd.ms-excel', //XLS
      'application/pdf', //PDF
      'application/rtf', //RTF
      'application/vnd.oasis.opendocument.presentation', // OpenDocument presentation
      'application/vnd.oasis.opendocument.spreadsheet', // OpenDocument spreadsheet
      'application/vnd.oasis.opendocument.text' // OpenDocument text
    ]
    return fileTypes
  }

  const tabContents: AssetTabNavigatorProps[] = [
    {title: t('documents:labels.documentsTabTitle', 'Documents'), assetType: AssetType.DOCUMENT}
  ]

  return (
    <CenteredModal isOpen={showUploader}>
      <WideModalContent>
        <AddAsset
          label={t(
            'documents:labels.addAsset',
            'Select from existing documents, or upload a new file. You can use e.g. PDF, text and MS Office file formats.'
          )}
          title={t('documents:labels.addDocument', 'Add document')}
          acceptedTypes={getAllowedFileTypes().join()}
          tabContents={tabContents}
          handleClose={toggleShowUploader}
          handleAddSelected={handleAddAsset}
          instructions={instructions}
        />
      </WideModalContent>
    </CenteredModal>
  )
}

interface DocumentAddingFormProps {
  document: Partial<Document> | Partial<InstructionsDocument>
  setDocument: (document: Partial<Document> | Partial<InstructionsDocument>) => void
  isEditing: boolean
  isDocumentOwner: () => boolean
  isInstructions: boolean
  folderOptions: {id: string; option: string}[]
  selectedDocumentFolder: DocumentFolder | InstructionsFolder
  selectMethod: (id: string) => void
  internalDocumentFolders: any
  t: any
}

export const DocumentAddingForm = ({
  document,
  setDocument,
  isEditing,
  isDocumentOwner,
  folderOptions,
  selectedDocumentFolder,
  isInstructions,
  selectMethod,
  internalDocumentFolders,
  t
}: DocumentAddingFormProps) => {
  return (
    <>
      {document.type ? (
        <BigLabelInput
          disabled={isEditing && !isDocumentOwner()}
          labelText={t('documents:labels.documentName', 'Document name')}
          placeholder={t('documents:placeholders.documentName', 'New document name')}
          name="name"
          id="name"
          value={document.name}
          onChange={e => setDocument({...document, name: e.target.value})}
        />
      ) : null}

      {document.type === DocumentType.External ? (
        <LabelTextArea
          required
          placeholder={t('documents:placeholders.link', 'Link')}
          labelText={t('documents:labels.link', 'Link')}
          name="url"
          id="url"
          value={document.url}
          onChange={e => setDocument({...document, url: e.target.value})}
        />
      ) : null}

      {document.type ? (
        <>
          <Select
            label={t('documents:labels.documentFolder', 'Document folder')}
            options={folderOptions}
            nativeProps={{
              name: 'documentFolder',
              onChange(e) {
                selectMethod(folderOptions.find(df => df.option === e.target.value)!.id)
              },
              value: selectedDocumentFolder ? selectedDocumentFolder.name : '',
              disabled: internalDocumentFolders.length === 0 || (isEditing && !isDocumentOwner() && !isInstructions)
            }}
            id="repeatTypeSelect"
          />
          {internalDocumentFolders.length === 0 && (
            <P>{t('documents:messages.createFoldersFirst', 'Create folders first to add a new document.')}</P>
          )}
        </>
      ) : null}
    </>
  )
}

interface DocumentEditBottomButtonsProps {
  isEditing: boolean
  isDocumentOwner: () => boolean
  isValid: () => boolean
  handleUpdate: () => void
  handleCreate: () => void
  handleCancel: () => void
  setModalOpen: (isModalOpen: boolean) => void
  t: any
}

export const DocumentEditBottomButtons = ({
  isEditing,
  isDocumentOwner,
  isValid,
  handleUpdate,
  handleCreate,
  handleCancel,
  setModalOpen,
  t
}: DocumentEditBottomButtonsProps) => {
  return (
    <ButtonRowWrap>
      {isEditing && isDocumentOwner() ? (
        <IconText
          onClick={() => setModalOpen(true)}
          icon={<IconDelete />}
          text={t('documents:actions.deleteDocument', 'Delete document')}
        />
      ) : (
        <div className="empty" />
      )}
      <ButtonRowGrid>
        <Button variant="secondary" negative onClick={() => handleCancel()}>
          {t('common:buttons.cancel', 'Cancel')}
        </Button>
        <Button
          variant="secondary"
          disabled={!isValid() || (isEditing && !isDocumentOwner())}
          onClick={() => (isEditing ? handleUpdate() : handleCreate())}
        >
          {t('common:buttons.save', 'Save')}
        </Button>
      </ButtonRowGrid>
    </ButtonRowWrap>
  )
}
